import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  /*
  const data = useStaticQuery(graphql`
    query speechQuery {
      img1: file(relativePath: { eq: "childpsy01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img2: file(relativePath: { eq: "childpsy02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 695) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  */

  const intl = useIntl()
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  const Part1 = ({ mykey }) => {
    const title = mykey + ".title"
    const desc = mykey + ".desc"
    const cause = mykey + ".cause"
    return (
      <div className="d-flex flex-column pt-2">
        <div className="font-weight-bold">{tt(title)}</div>
        <ul>
          <li>{tt(desc)}</li>
        </ul>
        <div className="pl-3">{tt("pages.speech.cause")}</div>
        <ul>
          <li>{tt(cause)}</li>
        </ul>
      </div>
    )
  }

  return (
    <Layout>
      <SEO
        title="seo.title.SpeechPathology"
        description="seo.desc.SpeechPathology"
      />
      <div className="content-master">
        <Container>
          <Row>
            <Col xs={12}>
              <h3>
                <div
                  css={css`
                    text-align: center;
                    font-weight: bold;
                  `}
                >
                  {tt("pages.speech.title1")}
                </div>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-dark">{tt("pages.speech.desc1")}</div>
            </Col>
          </Row>
          <Row>
            <Col className="pt-4">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">
                  {tt("pages.speech.part1.title")}
                </h5>
                <div css={css``} className="text-dark">
                  <Part1 mykey="pages.speech.part1.point1" />
                  <Part1 mykey="pages.speech.part1.point2" />
                  <Part1 mykey="pages.speech.part1.point3" />
                  <Part1 mykey="pages.speech.part1.point4" />
                  <Part1 mykey="pages.speech.part1.point5" />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="pt-4">
              <div className="d-flex flex-column">
                <h5 className="font-weight-bold">
                  {tt("pages.speech.part2.title")}
                </h5>
                <div css={css``} className="text-dark">
                  <ol>
                    <li>{tt("pages.speech.part2.point1")}</li>
                    <li>{tt("pages.speech.part2.point2")}</li>
                    <li>{tt("pages.speech.part2.point3")}</li>
                    <li>{tt("pages.speech.part2.point4")}</li>
                  </ol>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default ThePage
